

<template>
  <div class="stationView">
    <main class="player-content flex-row">
      <section class="flex-1">
        <div class="flex-autorow flex-top flex-stretch player-channel">
          <!-- station details -->
<div class="flex-item flex-1">
<div class="push-bottom">
  <div class="card flex-item flex-top flex-stretch fx fx-slide-up fx-delay-4 flex-1">
<form @submit.prevent="submitForm" v-if="!formSubmitted">
      <span>Full Name</span><br>
      <input 
        v-model="name"
        type="text"
        placeholder="Enter your name" 
      /><br>
      <span>Email</span><br>
      <input 
        v-model="email"
        type="email"
        placeholder="Enter your email" 
      /><br>
      <span>Gender</span><br>
      <input 
        type="radio" 
        v-model="gender" 
        value="Male" 
      />
      <label>Male</label>
      <input 
        type="radio" 
        v-model="gender" 
        value="Female" 
      />
      <label>Female</label><br>
      <input 
        class="submit" 
        type="submit" 
        value="Submit"
      >
    </form>
      </div>
      </div>
      </div>


        </div>

        <!--<syncLyrics></syncLyrics>-->

      </section>
    </main>

  </div>
</template>

<script>
    /**
     * Home
     */
    import {mapGetters, mapState} from 'vuex';
    import favBtn from "@/views/components/favBtn";
    import SongsHistory from "@/views/station/components/songsHistory";
    import MainSong from "@/views/station/components/mainSong";
    import footerPlayer from '@/views/station/components/footerPlayer'

    export default {
        name: 'dedicace',
        components: {
            favBtn,
            MainSong,
            SongsHistory,
            footerPlayer
        },
        data: () => {
            return {
                // toggles
                visible: false,
                sidebar: false,
                errors: {},

            }
        },

    }
</script>